import React from "react";
import ContactUsThankYou from "../components/contact-us-thank-you";
import Layout from "../layout/";

const ContactUsThankYouPage = () => (
  <Layout excludeContactUs>
    <div className="flex h-[65vh] items-center justify-center p-8">
      <ContactUsThankYou />
    </div>
  </Layout>
);

export default ContactUsThankYouPage;
