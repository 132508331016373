import React from "react";
import Button from "../common/button";

const ContactUsThankYou = () => {
  return (
    <section className="flex h-[50vh] w-full flex-col items-center justify-center gap-6 px-8 text-center">
      <h1 className="dsc-h1 font-bold">お問い合わせを受け付けました。</h1>
      <div className="">
        <p>後ほど担当者よりご連絡いたします。</p>
      </div>

      <Button to="/">ホームページに戻る</Button>
    </section>
  );
};
export default ContactUsThankYou;
